import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { global } from "./global";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    url: string;
    urlCropper: string;

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.url = global.getUrl();
        this.urlCropper = global.getUrlCropper();
    }

    uploadFileIncidencia(fileToUpload: File, idAlbara: number, tipoFoto: string): Observable<any> {
        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto;

        const formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);

        return this.http.post(endpoint, formData, {headers: {Authorization: this.auth.getToken()}});
    }

    scannFotoAlbaran(image: File): Observable<any> {
        const endpoint = this.urlCropper + '/scannImage';

        const formData = new FormData();
        formData.append('image', image);

        return this.http.post(endpoint, formData); //, {headers: {Authorization: this.auth.getToken()}}
    }

    uploadFotoAlbaran(path: string, idAlbara: number, tipoFoto: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto;

        return this.http.post(endpoint, 'base64=' + encodeURIComponent(path), {headers});
    }

    uploadFotoLlegadasSalidas(fileToUpload: File, idAlbara: number, tipoFoto: string, idCheck: number): Observable<any> {
        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto + '&idCheck=' + idCheck;

        const formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);

        return this.http.post(endpoint, formData, {headers: {Authorization: this.auth.getToken()}});
    }

    uploadMandatoryPhotoSRCB(fileToUpload: File, idAlbara: number, tipoFoto: string, idCheck: string | undefined): Observable<any> {
        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto + '&idCheck=' + idCheck;

        const formData: FormData = new FormData();
        formData.append('Documents', fileToUpload, fileToUpload.name);

        return this.http.post(endpoint, formData, {headers: {Authorization: this.auth.getToken()}});
    }

    uploadFiles(base64: string, idAlbara: number, tipoFoto: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto;

        return this.http.post(endpoint, 'base64=' + encodeURIComponent(base64), {headers});
    }

    uploadFilesDocs(file: File, idAlbara: number, tipoFoto: string): Observable<any> {
        const endpoint = this.url + 'xofers/upload?reference=' + idAlbara + '&tipoFoto=' + tipoFoto;

        const formData: FormData = new FormData();
        formData.append('Documents', file, file.name);

        return this.http.post(endpoint, formData, {headers: {Authorization: this.auth.getToken()}});
    }

    getFiles(id: number): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'Application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getdocuments?idAlbara=' + id, {headers: headers});
    }

    getImages(id: number): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'Application/x-www-form-urlencoded')
            .set('Authorization', this.auth.getToken());

        return this.http.get(global.getUrl() + 'xofers/getimages?idAlbaran=' + id, {headers: headers});
    }
}
