import { Component, DoCheck, Inject, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, Platform, ToastController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { DOCUMENT, Location } from '@angular/common';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { QrDriver } from '../../../drivers/qr.driver';
import { QrServiceService } from "../../../services/qr-service.service";
import { UserService } from "../../../services/user.service";

@Component({
    selector: 'app-qr-reader',
    templateUrl: './qr-reader.page.html',
    styleUrls: ['./qr-reader.page.scss'],
})
export class QrReaderPage implements ViewWillEnter, DoCheck, ViewWillLeave {

    @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent | undefined;

    public inApp = this.platform.is('mobileweb') || this.platform.is('desktop') ? 'browser' : 'app';

    public procesing = false;
    public enabled = true;

    name = "Lector QR";

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE
    ];

    hasDevices: boolean | undefined;
    hasPermission: boolean | undefined;

    torchEnabled = false;
    tryHarder = false;

    availableDevices: MediaDeviceInfo[] | undefined;
    deviceCurrent: MediaDeviceInfo | undefined;
    deviceSelected: string | undefined;

    showCheck = false;

    showFail = false;

    constructor(
        private qrService: QrServiceService,
        private toast: ToastController,
        private router: Router,
        private preloader: LoadingController,
        private route: ActivatedRoute,
        private location: Location,
        private platform: Platform,
        private qrDriver: QrDriver,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.enabled = this.qrDriver.enabled;
    }

    ngDoCheck() {
        this.deviceCurrent = this.qrDriver.deviceCurrent;
        this.deviceSelected = this.qrDriver.deviceSelected;
        this.availableDevices = this.qrDriver.availableDevices;
        this.enabled = this.qrDriver.enabled;
    }

    async browserError(error: any) {
        await this.qrDriver.browserError(error);
    }

    async ionViewWillEnter() {
        this.enabled = true;
        await this.start();
    }

    async start() {
        this.qrDriver.start().then(async (code) => {
            await this.saveResult(code);
        }).finally(async () => {
            await this.start();
        });
    }

    async ionViewWillLeave() {
        this.enabled = false;
        await this.close();
    }

    async close() {
        await this.qrDriver.close();
    }

    async checkBrowser(code: string) {
        if (code && this.qrDriver.check(code)) {
            if (!this.procesing) {
                await this.saveResult(code).catch(() => {
                });
            }
        }else if(code && !this.qrDriver.check(code)){
            const toast = await this.toast.create({
                message: 'Error de lectura',
                color: 'danger',
                position: 'bottom',
                translucent: true,
                duration: 2000
            });
            await toast.present();
        }
    }

    saveResult(code: any) {
        return new Promise(async (resolve, reject) => {
            await this.router.navigate(['/albaranes', code]);
        });
    }

    flashCheck() {
        this.showCheck = true;

        setTimeout(() => {
            this.showCheck = false;
        }, 3000);
    }

    flashFail() {
        this.showFail = true;

        setTimeout(() => {
            this.showFail = false;
        }, 3000);
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.qrDriver.onCamerasFound(devices);
    }

    onDeviceSelectChange(selected: string) {
        this.qrDriver.onDeviceSelectChange(selected);
    }

    onDeviceChange(device: MediaDeviceInfo) {
        this.qrDriver.onDeviceChange(device);
    }

    onHasPermission(has: boolean) {
        this.qrDriver.onHasPermission(has);
    }


    onTorchCompatible(isCompatible: boolean): void {
        this.qrDriver.onTorchCompatible(isCompatible);
    }

    toggleTorch(): void {
        this.qrDriver.toggleTorch();
    }

    toggleTryHarder(): void {
        this.qrDriver.toggleTryHarder();
    }
}
