export class Albaran {
    id: number = 0;
    reference: string = '';
    volum: string = '';
    numeroBultos: string = '';
    pes: string = '';
    estat: string = '';
    origen: string = '';
    desti: string = '';
    nameClient: string = '';
    tipusTransport: string = '';
    idTransport: number = 0;
    numeroTareas: number = 0;
    numeroTareasHechas: number = 0;
    esAdr: number = 0;
    esExpress: number = 0;
    esPortaElevadora: number = 0;
    esRemuntable: number = 0;
    pnstrm: number = 0;
    modeTransport: number = 0;
    refClient: number = 0;
}
