<app-navbar [nameSection]="name"></app-navbar>

<div *ngIf="showLoader" class="loader-container">
    <div class="overlay">
        <div class="loader"></div>
    </div>
</div>

<div class="container-fluid">
    <div class="mb-3 row">
        <div *ngFor="let document of documents" class="col-12 col-md-6 mt-3">
            <div class="card">
                <div *ngIf="document.name.indexOf('pdf') !== -1" class="card-img-top text-center p-5">
                    <i class="fa fa-file-pdf fa-10x"></i>
                </div>
                <img *ngIf="document.name.indexOf('pdf') === -1" alt=""
                     class="card-img-top" src="{{url}}/entrades/download?id={{document.id}}">
                <div class="card-body">

                </div>
                <div class="card-footer">
                    <a [href]="url + '/entrades/download?id=' + document.id" class="w-100 btn btn-primary"
                       style="background-color: #3c2170; border: none;"
                       target="_blank">
                        <i *ngIf="document.name.indexOf('pdf') !== -1" class="fa fa-file-pdf"></i>
                        <i *ngIf="document.name.indexOf('pdf') === -1" class="fa fa-image"></i>
                        Descargar {{ document.nom }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
