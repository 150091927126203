export const CheckListTaskDriver = {
    arrivalAtLoadingPoint: 'LLPC', // Llegada al punto de carga.
    departureFromLoadingPoint: 'SPC', // Salida del punto de carga.
    arrivalAtUnloadingPoint: 'LLPD', // Llegada al punto de descarga.
    departureFromUnloadingPoint: 'SPD', // Salida del punto de descarga.
    arrivalAtWaypoint: 'LLPP', // Llegada al punto de paso.
    departureFromWaypoint: 'SPP', // Salida del punto de paso.
    enRouteToLoadingPoint: 'PPLL', // En camino hacia punto de carga.
    enRouteToWaypoint: 'CPP', // En camino hacia punto de paso.
    enRouteToUnloadingPoint: 'CPD', // En camino hacia punto de descarga.
    podOrEndOfWaybill: 'FPOD', // POD o finalización del albarán.
    arrivalAtEmptyContainerPickup: 'LLRCB', // Llegada recogida contenedor vacío.
    departureFromEmptyContainerPickup: 'SRCB', // Salida recogida contenedor vacío.
    arrivalAtEmptyContainerDelivery: 'LLECP', // Llegada entrega contenedor vacío.
    departureFromEmptyContainerDelivery: 'SECP', // Salida entrega contenedor vacío.
};
