<app-navbar [nameSection]="name"></app-navbar>

<div *ngIf="showLoader" class="loader-container">
    <div class="overlay">
        <div class="loader"></div>
    </div>
</div>

<div class="container-fluid">
    <input (change)="checkImage($event)" accept="image/*,application/pdf,.doc,.docx" id="albaranPhotoInput" name="file"
           type="file">
    <label class="form-control btn w-100" for="albaranPhotoInput" id="albaranPhotoLabel">
        <span>Subir documentación</span>
    </label>

    <div class="mb-3 row">
        <div *ngFor="let document of documents" class="col-12 col-md-6 mt-3">
            <div class="card">
                <div *ngIf="document.name.indexOf('pdf') !== -1" class="card-img-top text-center p-5">
                    <i class="fa fa-file-pdf fa-10x"></i>
                </div>
                <img *ngIf="document.name.indexOf('pdf') === -1" alt=""
                     class="card-img-top" src="{{url}}/entrades/download?id={{document.id}}">
                <div class="card-body">

                </div>
                <div class="card-footer">
                    <a [href]="url + '/entrades/download?id=' + document.id" class="w-100 btn btn-primary"
                       style="background-color: #3c2170; border: none;"
                       target="_blank">
                        <i *ngIf="document.name.indexOf('pdf') !== -1" class="fa fa-file-pdf"></i>
                        <i *ngIf="document.name.indexOf('pdf') === -1" class="fa fa-image"></i>
                        Descargar {{ document.nom }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showAlertUploadFoto" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertUploadFoto }}</p>
            <button (click)="closeAlertError()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- Button trigger modal -->
<div class="container-fluid">
    <button #buttonModal [attr.data-bs-target]="'#cesta'" [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'staticBackdropLabel'" [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="cesta" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="staticBackdropLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <image-cropper
                        (imageCropped)="imageCropped($event)"
                        [hidden]="isCroppedImage"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="false"
                        format="jpeg"
                    ></image-cropper>

                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" height="350" width="255">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="cropImage()" [hidden]="isCroppedImage"
                            class="form-control mb-1" type="button">
                        Recortar imagen
                    </button>
                    <button (click)="uploadImage()" class="form-control" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <!--<button (click)="uploadImageOriginal()" class="form-control" data-bs-dismiss="modal" type="button">
                        Guardar Original
                    </button>-->
                    <button class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
