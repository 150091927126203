import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QrServiceService {

    public lastResult = '';
    public returnPage = ['/panel'];
    public type: string = "";
    public data: { enterprise: number; course: number; type: string; } = {type: "", enterprise: 0, course: 0};
    public hasChanges = false;

    constructor() {
    }

    createString(id: string | number) {
        const parts = id.toString().split('');
        let calc = 0;
        for (const num of parts) {
            calc = calc + parseInt(num, 10);
        }
        let calcDigit = calc.toString();
        if (calc.toString().length > 1) {
            calcDigit = calcDigit.charAt(calcDigit.length - 1);
        }
        const calcLetter = String.fromCharCode(parseInt(calcDigit, 10) + 64);
        return calcLetter + id + calcDigit;
    }

}
