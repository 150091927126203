import { Incidencia } from "./incidencia";

export class Checklist {
    id: number = 0;
    nom: string = '';
    codi: string = '';
    reference: string = '';
    idAlbara: number = 0;
    idCheck: string = '';
    idXofer: number = 0;
    idIncidencia: number = 0;
    idDocument: number = 0;
    idLloc: number = 0;
    idOrigen: number = 0;
    idDesti: number = 0;
    incidencia: Incidencia[] = [];
    incidenciaMarcada: boolean = false;
    incidenciaMinimizada: boolean = false;
    incidenciaNoEditable: boolean = false;
    latitude: number = 0;
    longitude: number = 0;
    dataCheck: string = "";
    checked: number = 0;
    hasImage: number = 0;
    incidenceDisabled: boolean = false;
    newIncidence: boolean = false;
    imgIncidencia: boolean = false;
    nomDesc: string = "";
    pes: string = "";
    volum: string = "";
    bultos: string = "";
    isCheckedAWB: number = 0;
    isCheckedBultosDestinyAWB: number = 0;
    isPartial: number = 0;
    isTotal: number = 0;
    statusRoute: string = "";
    deliveryNoteDestination: boolean = false;
    taskCode: string = "";
    isDeliveryNoteSplit: boolean = false;
    remainingPackages: number = 0;
    originDeliveryNotePlace: string  ="";


}
