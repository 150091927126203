import { Inject, Injectable } from '@angular/core';
import { BarcodeScanner, CameraDirection, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { BehaviorSubject } from 'rxjs';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { QrServiceService } from "../services/qr-service.service";

@Injectable({
    providedIn: 'root'
})
export class QrDriver {
    public scanActive = false;
    public inApp = this.platform.is('mobileweb') || this.platform.is('desktop') ? 'browser' : 'browser';
    public enabled = false;

    supportFormats: SupportedFormat[] = [
        SupportedFormat.CODE_128,
        SupportedFormat.DATA_MATRIX,
        SupportedFormat.EAN_13,
        SupportedFormat.QR_CODE
    ];

    hasDevices: boolean | undefined;
    hasPermission: boolean | undefined;

    torchEnabled = false;
    tryHarder = false;

    availableDevices: MediaDeviceInfo[] | undefined;
    deviceCurrent: MediaDeviceInfo | undefined;
    deviceSelected: string | undefined;

    torchAvailable$ = new BehaviorSubject<boolean>(false);

    constructor(
        private qrModal: ModalController,
        private qrService: QrServiceService,
        private toast: ToastController,
        private router: Router,
        private preloader: LoadingController,
        private route: ActivatedRoute,
        private location: Location,
        private platform: Platform,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    async start() {
        return new Promise(async (resolve, reject) => {
            if (this.inApp === 'browser') {
                await this.delayAndTryHarder();
                reject();
            } else {
                this.startScannerApp().then((code) => {
                    resolve(code);
                }).catch(() => {
                    reject();
                });
            }
        });
    }


    async close() {
        if (this.inApp !== 'browser') {
            await this.showBackground();
            await BarcodeScanner.stopScan();
            this.scanActive = false;
        }
    }

    async startScannerApp() {
        return new Promise(async (resolve, reject) => {
            await this.checkPermission();
            this.scanActive = true;
            await this.hideBackground();
            const status = await BarcodeScanner.startScan({
                cameraDirection: CameraDirection.BACK,
                targetedFormats: this.supportFormats
            });
            if (status.hasContent && this.check(status.content)) {
                this.qrService.lastResult = status.content;
                resolve(status.content);
            } else {
                this.qrService.lastResult = "";
                const toast = await this.toast.create({
                    message: 'Error de lectura',
                    color: 'danger',
                    position: 'bottom',
                    translucent: true,
                    duration: 2000
                });
                await toast.present();
                this.scanActive = true;
                reject();
            }
        });

    }

    check(resultString: string): boolean {

        /*const numbers = resultString.slice(1, -1);
        const digit = resultString.charAt(resultString.length - 1);
        const parts = numbers.split('');
        const letter = resultString.charAt(0);
        const firstIsLetter = /^[A-Z]/i.test(letter);


        if (!firstIsLetter) {
            return false;
        }

        let calc = 0;

        for (const num of parts) {
            calc = calc + parseInt(num, 10);
        }
        let calcDigit = calc.toString();
        if (calc.toString().length > 1) {
            calcDigit = calcDigit.charAt(calcDigit.length - 1);
        }
        const calcLetter = String.fromCharCode(parseInt(calcDigit, 10) + 64);

        return digit.toString() === calcDigit.toString() && letter === calcLetter;*/
        return true;
    }

    async browserError(event: any) {
        const toast = await this.toast.create({
            message: event,
            color: 'danger',
            position: 'bottom',
            translucent: true,
            duration: 2000
        });
        await toast.present();
    }

    async checkPermission() {
        return new Promise(async (resolve, rejects) => {
            const status = await BarcodeScanner.checkPermission({force: true});
            if (status.granted) {
                resolve(true);
            } else if (status.denied) {
                this.toast.create({
                    header: 'Sin permiso',
                    message: 'Por favor, concede permiso para acceder a la camara',
                    color: 'danger',
                    buttons: [
                        {
                            text: 'no',
                            role: 'cancel'
                        },
                        {
                            text: 'Abir opciones',
                            handler: () => {
                                BarcodeScanner.openAppSettings();
                                resolve(false);
                            }
                        }
                    ]
                });
            } else {
                resolve(false);
            }
        });

    }

    async delayAndTryHarder() {
        await this.delay(1000);
        this.toggleTryHarder();
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);
    }

    onDeviceSelectChange(selected: string) {
        const selectedStr = selected || '';
        if (this.deviceSelected === selectedStr) {
            return;
        }
        this.deviceSelected = selectedStr;

        // @ts-ignore
        const device = this.availableDevices.find((x: { deviceId: string; }) => x.deviceId === selected);

        this.deviceCurrent = device || undefined;
    }

    onDeviceChange(device: MediaDeviceInfo) {
        const selectedStr = device?.deviceId || '';
        if (this.deviceSelected === selectedStr) {
            return;
        }
        this.deviceSelected = selectedStr;
        this.deviceCurrent = device || undefined;
    }


    onHasPermission(has: boolean) {
        this.hasPermission = has;
    }


    onTorchCompatible(isCompatible: boolean): void {
        this.torchAvailable$.next(isCompatible || false);
    }

    toggleTorch(): void {
        this.torchEnabled = !this.torchEnabled;
    }

    toggleTryHarder(): void {
        this.tryHarder = !this.tryHarder;

    }

    private async hideBackground() {
        await BarcodeScanner.hideBackground();
        this.document.body.style.setProperty('--ion-background-color', 'transparent');
    }

    private async showBackground() {
        await BarcodeScanner.showBackground();
        this.document.body.style.removeProperty('--ion-background-color');
    }
}
