import { Component } from '@angular/core';
import {global} from "../../../services/global";
import {Location} from "@angular/common";
import {UploadService} from "../../../services/upload.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-albaran-images',
  templateUrl: './albaran-images.component.html',
  styleUrls: ['./albaran-images.component.scss']
})
export class AlbaranImagesComponent {

    name = "";

    url = global.getUrl();

    showLoader: boolean = false;

    documents: any;
    reference: any;

    constructor(
        private location: Location,
        private uploadService: UploadService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(
            (params) => {
                this.reference = params['reference'];
                this.name = "Ver imagenes salidas y llegadas - " + params['reference'];
            }
        );

        this.showLoader = true;

        this.uploadService.getImages(this.reference).subscribe(
            img => {
                this.documents = img.documents;
                this.showLoader = false;
            }
        )
    }

}
