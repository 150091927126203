import {Component, OnInit, ViewChild} from '@angular/core';
import {Albaran} from '../../../models/albaran';
import {AlbaranService} from "../../../services/albaran.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Matricula} from "../../../models/matricula";
import {AuthService} from "../../../services/auth.service";
import {Lloc} from "../../../models/lloc";
import {lastValueFrom} from 'rxjs';
import {ZXingScannerComponent} from "@zxing/ngx-scanner";
import {BarcodeFormat} from "@zxing/library";
import {Platform, ToastController} from "@ionic/angular";
import {QrDriver} from "../../../drivers/qr.driver";

@Component({
    selector: 'app-albaran-list',
    templateUrl: './albaran-list.component.html',
    styleUrls: ['./albaran-list.component.scss']
})
export class AlbaranListComponent implements OnInit {

    nameSection = "Lista albaranes";

    @ViewChild('selectElement') selectElement!: any;

    inputFiltroAlbaran: string = "";
    inputFiltroAlbaranDate: string = "";

    nomXofer: any;
    matriculaXofer: any;
    selectedTuition: any;

    matriculas: Matricula[] = [];
    albaranes: Albaran[] = [];

    valorRecibido: number = -1;
    tareasHechasTotales: number = 0;
    tareasPendientesTotales: number = 0;

    loaderShow: boolean = false;
    boolHiddeBtnClearFilters: boolean = true;

    showAlertErrorGetAlbaranes: boolean = false;
    messageErrorGetAlbaranes: string = "";

    showQuestionDestiny: boolean = false;
    showAlertSaveTracking: boolean = false;
    messageAlertSaveTracking: string = "";
    listFirstDestinyXofer: Lloc[] = [];
    selectedCheckboxId: string | null = null;
    firstDestiny: any;

    showAdvanceAlbaran: boolean = false;
    showCardAdvanceAlbaran: boolean = false;
    showCameraAdvanceAlbarans: boolean = false;
    showMessageAdvancedAlbaranNotFound: boolean = false;
    albaranId: string = "";
    advancedAlbaran: Albaran = new Albaran();

    @ViewChild('scanner', {static: true}) scanner: ZXingScannerComponent | undefined;

    public procesing = false;
    public enabled = true;

    name = "Lector QR";

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE
    ];

    availableDevices: MediaDeviceInfo[] | undefined;
    deviceCurrent: MediaDeviceInfo | undefined;
    deviceSelected: string | undefined;

    showCheck = false;
    showFail = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private albaranService: AlbaranService,
        private auth: AuthService,
        private platform: Platform,
        private qrDriver: QrDriver,
        private toast: ToastController,
    ) {
    }

    async ngOnInit() {
        this.route.params.subscribe(params => {
            this.valorRecibido = params['reference'];
        });

        this.loaderShow = true;

        try {
            const xofers: any = await lastValueFrom(this.albaranService.getNameXofer());
            this.nomXofer = xofers.nomXofer;
            this.matriculaXofer = xofers.matriculaXofer;
            this.matriculas = xofers.matriculas;

            if(localStorage.getItem('matricula') === null) {
                localStorage.setItem('matricula', this.matriculaXofer);
            }

            this.selectedTuition = localStorage.getItem('matricula');

            const actualDate = new Date();
            const dd = String(actualDate.getDate()).padStart(2, '0');
            const mm = String(actualDate.getMonth() + 1).padStart(2, '0');
            const yyyy = actualDate.getFullYear();

            this.inputFiltroAlbaranDate = yyyy + '-' + mm + '-' + dd;
            //this.inputFiltroAlbaranDate = "2015-08-26";

            let dateAdvancedAlbarans: any = localStorage.getItem("dateAdvancedAlbarans");

            if(dateAdvancedAlbarans !== this.inputFiltroAlbaranDate) {
                localStorage.removeItem("advancedAlbarans");
                localStorage.removeItem("dateAdvancedAlbarans");
            }

            let advancedAlbarans = localStorage.getItem("advancedAlbarans");

            let albaranes: any;
            if(advancedAlbarans)
                albaranes = await lastValueFrom(this.albaranService.getAlbaranes(advancedAlbarans));
            else
                albaranes = await lastValueFrom(this.albaranService.getAlbaranes(null));

            this.tareasHechasTotales = 0;
            this.tareasPendientesTotales = 0;

            if(albaranes.length === 0) {
                this.albaranes = [];
            } else {
                this.albaranes = [];

                albaranes.forEach((albaran: any) => {
                    if (this.valorRecibido !== undefined) {
                        if (this.valorRecibido === albaran.reference) {
                            this.albaranes.push(albaran)
                            this.tareasHechasTotales += albaran.numeroTareasHechas;
                            this.tareasPendientesTotales += albaran.numeroTareas;
                        }
                    } else {
                        this.albaranes.push(albaran)
                        this.tareasHechasTotales += albaran.numeroTareasHechas;
                        this.tareasPendientesTotales += albaran.numeroTareas;
                    }
                });
            }

            const registros = Object.values(this.albaranes);

            registros.sort((a, b) => {
                if (a.estat === "Pre assignat" && b.estat !== "Pre assignat") {
                    return -1;
                } else if (a.estat !== "Pre assignat" && b.estat === "Pre assignat") {
                    return 1;
                } else {
                    return 0;
                }
            });

            this.albaranes = registros;

            this.showQuestionDestiny = this.auth.showQuestionDestiny;

            if(this.showQuestionDestiny) {
                const destiny: any = await lastValueFrom(this.albaranService.getListFirstDestinyXofer(this.inputFiltroAlbaranDate));
                this.listFirstDestinyXofer = destiny;
                this.auth.showQuestionDestiny = false;
            }

            this.loaderShow = false;
        } catch (error) {
            await this.router.navigate(['/login']);
            this.loaderShow = false;
        }
    }

    saveDestinationPlaceId(idLloc: any) {
        if (this.selectedCheckboxId === idLloc) {
            this.selectedCheckboxId = null;
            this.firstDestiny = null;
        } else {
            this.selectedCheckboxId = idLloc;
            this.firstDestiny = idLloc;
        }
    }

    saveFirstDestiny() {
        this.loaderShow = true;
        this.showQuestionDestiny = false;

        this.albaranService.saveFirstDestinyXofer(this.firstDestiny, this.selectedTuition, this.inputFiltroAlbaranDate).subscribe(
            {
                next: next => {
                    this.showAlertSaveTracking = true;
                    this.messageAlertSaveTracking = "Guardado correctamente.";

                    this.loaderShow = false;
                }, error: error => {
                    this.showAlertSaveTracking = true;
                    this.messageAlertSaveTracking = "Error al guardar el sitio al que te dirijes.";

                    this.loaderShow = false;
                }
            }
        );
    }

    closeSaveFirstDestiny() {
        this.showQuestionDestiny = false;
    }
    closeAlertSaveFirstDestiny() {
        this.showAlertSaveTracking = false;
    }

    getAlbaranesFiltrados() {
        let fecha = new Date('2000-01-01')
        let fechafiltro = new Date(this.inputFiltroAlbaranDate)
        let currentDate = new Date();

        if(currentDate < fechafiltro) {
            this.messageErrorGetAlbaranes = "No hay albaranes en la fecha seleccionada.";
            this.showAlertErrorGetAlbaranes = true;
            this.albaranes = [];

            return;
        }

        if (fechafiltro > fecha) {
            this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;
            if (this.boolHiddeBtnClearFilters)
                this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;

            this.tareasHechasTotales = 0;
            this.tareasPendientesTotales = 0;

            this.albaranService.getAlbaranesFiltrados(this.inputFiltroAlbaranDate, this.inputFiltroAlbaran).subscribe(
                {
                    next: albaranes => {
                        this.showAlertErrorGetAlbaranes = false;

                        this.albaranes = albaranes;

                        albaranes.forEach((albaran: any) => {
                            this.tareasHechasTotales += albaran.numeroTareasHechas;
                        });

                        this.tareasPendientesTotales = albaranes.length * 6;

                        const registros = Object.values(this.albaranes);

                        registros.sort((a, b) => {
                            if (a.estat === "Pre assignat" && b.estat !== "Pre assignat") {
                                return -1;
                            } else if (a.estat !== "Pre assignat" && b.estat === "Pre assignat") {
                                return 1;
                            }

                            if (a.estat === "Pre assignat parcial" && b.estat !== "Pre assignat parcial") {
                                return -1;
                            } else if (a.estat !== "Pre assignat parcial" && b.estat === "Pre assignat parcial") {
                                return 1;
                            }

                            if (a.estat === "Assignat" && b.estat !== "Assignat") {
                                return -1;
                            } else if (a.estat !== "Assignat" && b.estat === "Assignat") {
                                return 1;
                            }

                            if (a.estat === "Iniciat" && b.estat !== "Iniciat") {
                                return -1;
                            } else if (a.estat !== "Iniciat" && b.estat === "Iniciat") {
                                return 1;
                            }

                            return 0;
                        });

                        this.albaranes = [];
                        this.albaranes = registros;
                    }, error: error => {
                        this.messageErrorGetAlbaranes = error.error.message;
                        this.showAlertErrorGetAlbaranes = true;
                        this.albaranes = [];
                    }
                }
            )
        }
    }

    changeToken() {
        let selectedTuition = this.selectedTuition;
        let tuitonSearched = this.matriculas.find(function(objeto) {
            return objeto.matricula === selectedTuition;
        });

        if(tuitonSearched) {
            this.auth.chengeTokenByTuiton(this.selectedTuition, tuitonSearched.idXofer).subscribe(
                {
                    next: async next => {
                        localStorage.setItem('token', next.token);
                        localStorage.setItem('matricula', this.selectedTuition);
                        await this.ngOnInit();
                    }
                }
            )
        }
    }

    async clearFilters() {
        this.albaranes = [];
        this.inputFiltroAlbaran = "";
        this.inputFiltroAlbaranDate = "";
        this.boolHiddeBtnClearFilters = !this.boolHiddeBtnClearFilters;
        await this.ngOnInit();
    }

    vaciarInputDate() {
        this.inputFiltroAlbaranDate = "";
    }

    closeAlertError() {
        this.showAlertErrorGetAlbaranes = false;
    }

    async advanceAlbaran(): Promise<void> {
        this.showAdvanceAlbaran = false;
        this.showCardAdvanceAlbaran = false;
        this.loaderShow = true;

        try {
            let albarans = await lastValueFrom(this.albaranService.getAdvanceAlbaran(this.albaranId));

            this.advancedAlbaran = <Albaran>albarans;

            if(this.advancedAlbaran.reference) {
                this.showCardAdvanceAlbaran = true;
                this.showCameraAdvanceAlbarans = false;
            } else {
                this.showCameraAdvanceAlbarans = false;
                this.showMessageAdvancedAlbaranNotFound = true;
            }

            this.loaderShow = false;
            this.showAdvanceAlbaran = true;
        } catch (error) {
            this.loaderShow = false;
        }
    }

    async saveAdvancedAlbaran(reference: string): Promise<void> {
        let oldArray: any = localStorage.getItem("advancedAlbarans");

        if (oldArray) {
            let newArray = JSON.parse(oldArray);

            newArray.push(reference);

            localStorage.removeItem("advancedAlbarans")
            localStorage.setItem("advancedAlbarans", JSON.stringify(newArray));
        } else {
            let newArray = [reference];

            localStorage.setItem("advancedAlbarans", JSON.stringify(newArray));
            localStorage.setItem("dateAdvancedAlbarans", this.inputFiltroAlbaranDate);
        }

        this.showMessageAdvancedAlbaranNotFound = false;
        this.showCameraAdvanceAlbarans = true;
        this.showCardAdvanceAlbaran = false;
        this.albaranId = "";
        this.showAdvanceAlbaran = false;
        await this.ngOnInit();
    }

    retryFoundAdvancedAlbaran(): void {
        this.showMessageAdvancedAlbaranNotFound = false;
        this.showCameraAdvanceAlbarans = true;
        this.showCardAdvanceAlbaran = false;
        this.albaranId = "";
    }

    openModalAdvanceAlbaran(): void {
        this.showAdvanceAlbaran = true;
        this.showCameraAdvanceAlbarans = true;
    }

    closeModalAdvanceAlbaran(): void {
        this.albaranId = "";
        this.showCardAdvanceAlbaran = false;
        this.showCameraAdvanceAlbarans = false;
        this.showMessageAdvancedAlbaranNotFound = false;
        this.showAdvanceAlbaran = false;
    }

    //LECTOR QR

    ngDoCheck() {
        this.deviceCurrent = this.qrDriver.deviceCurrent;
        this.deviceSelected = this.qrDriver.deviceSelected;
        this.availableDevices = this.qrDriver.availableDevices;
        this.enabled = this.qrDriver.enabled;
    }

    browserError(error: any) {
        this.qrDriver.browserError(error);
    }

    async ionViewWillEnter() {
        this.enabled = true;
        await this.start();
    }

    async start() {
        this.qrDriver.start().then(async (code) => {
            await this.saveResult(code);
        }).finally(async () => {
            await this.start();
        });
    }

    async ionViewWillLeave() {
        this.enabled = false;
        await this.close();
    }

    async close() {
        await this.qrDriver.close();
    }

    async checkBrowser(code: string) {
        if (code && this.qrDriver.check(code)) {
            if (!this.procesing) {
                await this.saveResult(code).catch(() => {
                });
            }
        }else if(code && !this.qrDriver.check(code)){
            const toast = await this.toast.create({
                message: 'Error de lectura',
                color: 'danger',
                position: 'bottom',
                translucent: true,
                duration: 2000
            });
            await toast.present();
        }
    }

    async saveResult(code: any) {
        console.log(code);

        this.albaranId = code;
        await this.advanceAlbaran();

        return new Promise(async (resolve, reject) => {
            //await this.router.navigate(['/albaranes', code]);
        });
    }

    flashCheck() {
        this.showCheck = true;

        setTimeout(() => {
            this.showCheck = false;
        }, 3000);
    }

    flashFail() {
        this.showFail = true;

        setTimeout(() => {
            this.showFail = false;
        }, 3000);
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.qrDriver.onCamerasFound(devices);
    }

    onDeviceSelectChange(selected: string) {
        this.qrDriver.onDeviceSelectChange(selected);
    }

    onDeviceChange(device: MediaDeviceInfo) {
        this.qrDriver.onDeviceChange(device);
    }

    onHasPermission(has: boolean) {
        this.qrDriver.onHasPermission(has);
    }


    onTorchCompatible(isCompatible: boolean): void {
        this.qrDriver.onTorchCompatible(isCompatible);
    }

    toggleTorch(): void {
        this.qrDriver.toggleTorch();
    }

    toggleTryHarder(): void {
        this.qrDriver.toggleTryHarder();
    }

}
