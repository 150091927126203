import { Component, Input } from '@angular/core';
import { Incidencia } from "../../../models/incidencia";

@Component({
    selector: 'app-albaran-progress-incidencia-list',
    templateUrl: './albaran-progress-incidencia-list.component.html',
    styleUrls: ['./albaran-progress-incidencia-list.component.scss']
})
export class AlbaranProgressIncidenciaListComponent {

    @Input() incidencia: Incidencia = new Incidencia();

    constructor() {

    }

    minimizeIncidence(incidencia: Incidencia) {
        incidencia.incidenciaMinimizada = !incidencia.incidenciaMinimizada;
    }

}
