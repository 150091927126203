import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { global } from "./global";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    showQuestionDestiny: boolean = false;
    url: string = global.getUrl();
    token: any;
    identity: any;

    constructor(
        public http: HttpClient,
    ) {
        this.url = global.getUrl();
    }

    getToken() {
        let token = localStorage.getItem('token');

        if (token != 'undefined') {
            this.token = token;
        } else {
            this.token = null;
        }

        return this.token;
    }

    clearToken(): void {
        let token = localStorage.getItem('token');

        if (token) {
            localStorage.removeItem('token');
            this.token = null;
        }
    }

    check() {
        return !!localStorage.getItem('token');
    }

    /*getIdentity() {
        let identity = JSON.parse(localStorage.getItem('identity'));

        if (identity && identity != 'undefined') {
            this.identity = identity;
        } else {
            this.identity = null;
        }

        return this.identity;
    }*/

    isLogged(user: any, gettoken: boolean): Observable<any> {
        if (gettoken != false) {
            user.gettoken = 'true';
        }

        let params = "json=" + JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(this.url + 'xofers/loginxofers', params, {headers: headers});
    }

    auth(user: any, gettoken: boolean): Observable<any> {
        if (gettoken != false) {
            user.gettoken = 'true';
        }

        let params = "json=" + JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(this.url + 'xofers/loginxofers', params, {headers: headers});
    }

    chengeTokenByTuiton(tuiton: any, idXofer: any): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', this.getToken());

        return this.http.get(this.url + 'xofers/changetokenbytuiton?matricula=' + tuiton + '&idXofer=' + idXofer, {headers: headers});
    }
}
