<div class="login-container">
    <form (submit)="$event.preventDefault(); login();" autocomplete="off">
        <img height=100 src="assets/images/logo_definitiu.png" width="260">
        <h1>Iniciar sesión</h1>
        <div *ngIf="error" class="alert alert-danger text-center">
            Usuario incorrecto
        </div>
        <div *ngIf="success" class="alert alert-success text-center">
            Usuario correcto, entrando...
        </div>
        <div *ngIf="loading" class="alert alert-warning text-center">
            Comprobando credenciales...
        </div>
        <input #username="ngModel" [(ngModel)]="user.username" name="username" placeholder="Usuario" required
               type="text">
        <input #password="ngModel" [(ngModel)]="user.password" name="password" placeholder="Contraseña" type="password">
        <button class="btn btn-secondary" type="submit">
            ENTRAR
        </button>
    </form>
</div>
