import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Incidencia } from "../../../models/incidencia";
import { AlbaranService } from "../../../services/albaran.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { UploadService } from "../../../services/upload.service";
import { Checklist } from "../../../models/checklist";
import { AlbaranProgressComponent } from "../albaran-progress/albaran-progress.component";

@Component({
    selector: 'app-albaran-progress-new-incidencia',
    templateUrl: './albaran-progress-new-incidencia.component.html',
    styleUrls: ['./albaran-progress-new-incidencia.component.scss']
})
export class AlbaranProgressNewIncidenciaComponent {

    @Input() newIncidencia: Checklist = new Checklist();
    @Input() idTransport: any;
    @Input() idReference: any;
    @ViewChild('buttonModal') buttonModal!: ElementRef;

    base64image: any;
    file: any;

    incidencia: Incidencia = new Incidencia();

    boolSendIncidencia: boolean = false;
    buttonHidden: boolean = true;

    showAlertSuccessUploadPhotoIncidencia: boolean = false;
    messageSuccessIncidencia: string = "";
    showAlertErrorUploadPhotoIncidencia: boolean = false;
    messageErrorIncidencia: string = "";

    showAlertSendIncidencia: boolean = false;
    messageSendIncidencia: string = "";

    constructor(
        private albaranService: AlbaranService,
        private route: ActivatedRoute,
        private location: Location,
        private uploadService: UploadService,
        private albaranProgress: AlbaranProgressComponent
    ) {
    }

    previewImage(event: Event) {
        let input = event.target as HTMLInputElement;

        // @ts-ignore
        this.file = input.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const imagePreviewUrl = e.target.result;
            this.base64image = imagePreviewUrl.split(',')[1];
        };

        reader.readAsDataURL(this.file);

        this.buttonModal.nativeElement.click();
    }

    uploadFotoIncidencia() {
        this.albaranProgress.showLoader = true;

        this.uploadService.uploadFileIncidencia(this.file, this.idReference, "incidencia").subscribe(
            {
                next: next => {
                    this.showAlertSuccessUploadPhotoIncidencia = !this.showAlertSuccessUploadPhotoIncidencia;
                    this.messageSuccessIncidencia = next.message;
                    this.boolSendIncidencia = true;
                    this.albaranProgress.showLoader = false;
                }, error: error => {
                    this.showAlertErrorUploadPhotoIncidencia = !this.showAlertErrorUploadPhotoIncidencia;
                    this.messageErrorIncidencia = error.error.message;
                    this.albaranProgress.showLoader = false;
                }
            }
        );
    }

    closeAlertSuccessIncidencia() {
        this.showAlertSuccessUploadPhotoIncidencia = !this.showAlertSuccessUploadPhotoIncidencia;
    }

    closeAlertErrorIncidencia() {
        this.showAlertErrorUploadPhotoIncidencia = !this.showAlertErrorUploadPhotoIncidencia;
    }

    sendIncidencia(idCheck: any, idLloc: any) {
        this.incidencia.lloc = idLloc;
        this.albaranProgress.showLoader = true;

        this.albaranService.sendIncidencia(this.incidencia, this.idReference, this.idTransport, idCheck).subscribe(
            {
                next: next => {
                    this.showAlertSendIncidencia = !this.showAlertSendIncidencia;
                    this.messageSendIncidencia = next.message;
                    this.albaranProgress.showLoader = false;
                },
                error: error => {
                    this.showAlertSendIncidencia = !this.showAlertSendIncidencia;
                    this.messageSendIncidencia = error.error.message;
                    this.albaranProgress.showLoader = false;
                }
            }
        )
    }

    async closeAlertSendIncidencia() {
        this.showAlertSendIncidencia = !this.showAlertSendIncidencia;
        await this.albaranProgress.reload();
    }

}
