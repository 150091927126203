<div class="container-fluid mt-1 mb-1" style="display: grid; justify-items: center;">
    <img height=100 src="assets/images/logo_definitiu.png" width="260">
</div>

<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center w-100">
            <button (click)="goBack()" *ngIf="!shouldHideButton()" class="form-control"
                    style="background-color: #65489C; color: white; width: 120px !important; border-color: white">
                <i class="fa fa-arrow-left"></i>
                Volver
            </button>
            <button (click)="refreshNav()" *ngIf="shouldHideButtonRefresh()" class="form-control"
                    style="background-color: #65489C; color: white; width: 160px !important; border-color: white">
                Limpiar filtro QR
            </button>
            <span class="navbar-text" style="margin-left: 10px; font-size: 15px;">{{ nameSection }}</span>
            <div *ngIf="shouldHideButton()" class="text-center">
                <span class="navbar-text" style="font-size: 13px;">Bienvenido, {{ nameXofer }}</span>
                <i (click)="signOut()" class="fa fa-sign-out"></i>
            </div>
        </div>
    </div>
</nav>
