<app-navbar [nameSection]="name"></app-navbar>

<div *ngIf="showLoader" class="loader-container">
    <div class="overlay">
        <div class="loader"></div>
    </div>
</div>

<div class="container-fluid mb-5" style="display: grid; justify-items: center;">
    <button [routerLink]="['/progress', reference, idTransport]" class="form-control btn w-100 mt-4"
            id="progress"
            style="background-color: #f2e938; height: 50px; color: black; margin-bottom: 10px;">
        <b>PROGRESO ALBARÁN</b>
    </button>
    <button [routerLink]="['/upload-photo', reference, idTransport]" class="form-control btn w-100"
            id="albaranPhoto"
            style="background-color: #3c2170; height: 50px; color: white; margin-bottom: 10px;">
        Documentación del albarán
    </button>
    <button [routerLink]="['/show-images', reference, idTransport]" class="form-control btn w-100"
            id="showImages"
            style="background-color: #3c2170; height: 50px; color: white; margin-bottom: 10px;">
        Ver imagenes de las llegadas y salidas
    </button>
    <a [href]="url + 'entrades/downloadalbara?id=' + idAlbaran" class="w-100" target="_blank">
        <button class="form-control btn w-100" id="downloadAlbaranPDF"
                style="background-color: #3c2170; height: 50px; color: white; margin-bottom: 10px;">
            Descarga albarán TRM
        </button>
    </a>

    <ng-container *ngIf="infoAlbara.esAdr === 1">
        <ng-container *ngFor="let rutes of infoAlbara.rutesADR; let i = index">
            <a class="w-100"
               href="https://ximp.gencat.cat/appximp/routedispatcher?servicetype=pdf&numticket={{rutes.numTicket}}&iditinerari={{rutes.idItinerari}}&numruta=1&lang=ca&BASELAYER=default_google_2019"
               target="_blank">
                <button class="form-control btn w-100" id="downloadPDFRutaAdr"
                        style="background-color: #3c2170; height: 50px; color: white; margin-bottom: 10px;">
                    Descargar PDF con la ruta ADR {{ i === 0 ? 'Ida' : 'Vuelta' }}
                </button>
            </a>
        </ng-container>
    </ng-container>
</div>

<div class="container-fluid">
    <div class="card">
        <div class="card-header" style="background-color: #3c2170;">
            <span style="color:white;"><b>Información albarán</b></span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 mb-4">
                    <h4>DETALLES - {{ infoAlbara.idAlbara }}</h4>
                    <b>Bultos: {{ infoAlbara.bultos }} b<br></b>
                    <b>Peso: {{ infoAlbara.peso }} kg<br></b>
                    <b>Volumen: {{ infoAlbara.volum }} m³<br></b>

                    <ng-container *ngIf="infoAlbara.modeTransport === 'AERI'">
                        <b>Numero AWB: </b>{{ infoAlbara.numAWB }}<br>
                    </ng-container>

                    <b>Modo transporte: </b>{{ infoAlbara.modeTransport }}<br>
                    <b>Origen: </b>{{ infoAlbara.origen }}, {{ infoAlbara.cpOrigen }}, {{ infoAlbara.poblacioOrigen }}
                    <br>
                    <b>Destino: </b>{{ infoAlbara.destino }}, {{ infoAlbara.cpDestino }}
                    , {{ infoAlbara.poblacioDestino }}<br>
                    <b>KM: </b>{{ infoAlbara.km }}<br>
                    <b>Booking mercaderia: </b>{{ infoAlbara.booking }}<br>
                    <b>Mozos de almacén: </b>{{ infoAlbara.mossos >= 1 ? 'Sí' : 'No' }}
                </div>
                <hr>
                <div class="col-12 mb-4">
                    <h4>CLIENTE</h4>
                    <b>Cliente: </b>{{ infoAlbara.client }}<br>
                    <b>Referencia cliente: </b>{{ infoAlbara.refClient }}
                </div>
            </div>
        </div>
    </div>
</div>

