import { RutesADR } from "./rutesADR";

export class InfoAlbaran {
    numAWB: string = '';
    reference: string = '';
    idAlbara: string = '';
    comentaris: string = '';
    km: number = 0;
    kmAnada: number = 0;
    kmTornada: number = 0;
    booking: string = '';
    mossos: number = 0;
    rutesADR: RutesADR[] = [];
    esAdr: number = 0;
    client: string = '';
    refClient: string = '';
    origen: string = '';
    destino: string = '';
    cpOrigen: string = '';
    cpDestino: string = '';
    poblacioOrigen: string = '';
    poblacioDestino: string = '';
    modeTransport: string = '';
    bultos: number = 0;
    peso: number = 0;
    volum: number = 0;
    transportDates: string  ='';
    idOrigin: string = '';
    idDestination: string = '';
}
