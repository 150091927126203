import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Location} from '@angular/common';
import {AlbaranService} from "../../../services/albaran.service";
import {InfoAlbaran} from "../../../models/infoAlbaran";
import {global} from "../../../services/global";

@Component({
    selector: 'app-albaran-content',
    templateUrl: './albaran-content.component.html',
    styleUrls: ['./albaran-content.component.scss']
})
export class AlbaranContentComponent {

    name: string = "";
    url = global.getUrl();

    infoAlbara: InfoAlbaran = new InfoAlbaran();

    numeroRuta: number = 0;

    reference: any;
    idTransport: any;
    idAlbaran: any;

    showLoader: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private albaranService: AlbaranService,
    ) {
        this.route.params.subscribe(
            (params) => {
                this.name = "Albarán - " + params['reference'];
                this.reference = params['reference'];
                this.idTransport = params['idTransport'];
                this.idAlbaran = params['id'];
            }
        );
    }

    ngOnInit(): void {
        this.showLoader = true;

        this.albaranService.getInfoAlbaran(this.reference).subscribe(
            {
                next: infoAlbaran => {
                    this.infoAlbara = infoAlbaran;
                    this.infoAlbara.rutesADR = infoAlbaran.rutasADR;
                    this.numeroRuta = 1;

                    this.albaranService.modeTransport = infoAlbaran.modeTransport;
                    this.albaranService.tipusTransport = infoAlbaran.tipusTransport;
                    this.albaranService.idRecollidaEntregaContenidor = infoAlbaran.idRecollidaEntregaContenidor;

                    this.showLoader = false;
                }
            }
        )
    }
}
