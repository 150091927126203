import {AfterViewInit, Component} from '@angular/core';
import {AuthService} from './services/auth.service';
import {Router} from "@angular/router";
import {DbService} from "./services/db.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'xofers';
    loaded: boolean = false;
    isLogged: boolean = false;
    latitude: number = 0;
    longitude: number = 0;

    constructor(
        private _auth: AuthService,
        private router: Router,
        private dbService: DbService,
    ) {
    }

    async ngAfterViewInit() {
        if(!this.dbService.uploadingImages) {
            setInterval(() => {
                this.dbService.existsImages().then(images => {
                    if (images.length > 0) {
                        this.dbService.uploadPhotos();
                    } else {
                        //console.log("no hay nada");
                    }
                }).catch(err => {
                    console.error('Error al obtener las imágenes:', err);
                });
            }, 60000);
        }

        if (this._auth.check()) {
            this.isLogged = true;
            await this.router.navigate(['/albaranes']);
        } else {
            this.isLogged = false;
        }

        this.loaded = false;

        navigator.geolocation.getCurrentPosition((values) => {
            this.latitude = values?.coords?.latitude;
            this.longitude = values?.coords?.longitude;
        }, function (error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    alert("El usuario denegó el permiso para la Geolocalización.")
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("La ubicación no está disponible.")
                    break;
                case error.TIMEOUT:
                    alert("Se ha excedido el tiempo para obtener la ubicación.")
                    break;
                default:
                    alert("Se ha producido un error.")
                    break;
            }
        });
    }
}
