import { Injectable } from '@angular/core';
import { IDBPDatabase, openDB } from "idb";
import { Db } from "../models/Db";
import { Image } from "../models/Image";
import { UploadService } from "./upload.service";
import { global } from "./global";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class DbService {

    uploadingImages: boolean = false;
    url: string;
    urlCropper: string;

    private dbPromise: Promise<IDBPDatabase<Db>>;

    constructor(
        private uploadService: UploadService,
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.url = global.getUrl();
        this.urlCropper = global.getUrlCropper();

        this.dbPromise = openDB<Db>('imagesDB', 1, {
            upgrade(db) {
                let activitatsStore = db.createObjectStore('images', {keyPath: 'id', autoIncrement: true});
            }
        });
    }

    async addImage(item: Image) {
        const db = await this.dbPromise;
        return await db.put('images', item);
    }

    async existsImages() {
        const db = await this.dbPromise;
        return await db.getAll('images');
    }

    async uploadPhotos() {
        let images = await this.existsImages();

        for (let image of images) {
            if (image.tipoFoto === "albaranFoto") {
                this.uploadService.uploadFotoLlegadasSalidas(image.image, image.albara, image.tipoFoto, image.idCheck).subscribe(
                    {
                        next: async next => {
                            const db = await this.dbPromise;
                            return await db.clear('images');
                        }
                        ,
                        error: error => {

                        }
                    }
                );
            } else if (image.tipoFoto === "finalitzacioAlbara") {
                this.uploadService.uploadFotoAlbaran(image.base64, image.albara, image.tipoFoto).subscribe(
                    {
                        next: async next => {
                            const db = await this.dbPromise;
                            return await db.clear('images');
                        }, error: error => {

                        }
                    }
                );
            }
        }
    }

}
