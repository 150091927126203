import {Component, ElementRef, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {UploadService} from "../../../services/upload.service";
import {ActivatedRoute} from "@angular/router";
import {global} from '../../../services/global';

@Component({
    selector: 'app-albaran-upload-photo',
    templateUrl: './albaran-upload-photo.component.html',
    styleUrls: ['./albaran-upload-photo.component.scss']
})
export class AlbaranUploadPhotoComponent {

    name = "";

    @ViewChild('buttonModal') buttonModal!: ElementRef;

    url = global.getUrl();
    scanResult: any;
    documents: any;
    reference: any;
    base64: any;
    base64Original: any;
    unescannedImage: any;
    imageChangedEvent: any = '';
    croppedImage: any = '';

    close = false;
    showAlertUploadFoto = false;
    buttonHidden: boolean = true;
    scannSuccess: boolean = false;
    showLoader: boolean = false;
    isCroppedImage: boolean = false;

    messageAlertUploadFoto: string = "";

    constructor(
        private location: Location,
        private uploadService: UploadService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(
            (params) => {
                this.reference = params['reference'];
                this.name = "Documentación albarán - " + params['reference'];
            }
        );

        this.showLoader = true;

        this.uploadService.getFiles(this.reference).subscribe(
            doc => {
                this.documents = doc.documents;
                this.showLoader = false;
            }
        )
    }

    checkImage(event: Event) {
        let input = event.target as HTMLInputElement;

        if (!input.files || !input.files[0]) {
            // TODO: Añadir mensaje de excepcion si no llega archivo (Un pop up por ejemplo)
            return false;
        }

        let file = input.files[0];

        if (!file) {
            this.messageAlertUploadFoto = "No existe documento, recargue la página y vuelva a intentarlo.";
            this.showAlertUploadFoto = !this.showAlertUploadFoto;
        } else {
            if (file.type.startsWith('image/')) {
                this.imageChangedEvent = event;
                this.buttonModal.nativeElement.click();
            } else if (
                file.type === 'application/pdf' ||
                file.type === 'application/msword' ||
                file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                this.uploadService.uploadFilesDocs(file, this.reference, "doc").subscribe(
                    next => {
                        this.messageAlertUploadFoto = next.message;
                        this.showAlertUploadFoto = !this.showAlertUploadFoto;
                        this.ngOnInit();
                    },
                    error => {
                        this.messageAlertUploadFoto = error.message;
                        this.showAlertUploadFoto = !this.showAlertUploadFoto;
                    }
                );
            } else {
                this.messageAlertUploadFoto = "El archivo que has subido no es correcto, tiene que ser un documento o una imagen. Recargue e inténtelo de nuevo.";
                this.showAlertUploadFoto = !this.showAlertUploadFoto;
            }
        }

        return true;
    }

    imageCropped(event: any): void {
        this.croppedImage = event.objectUrl;
    }

    cropImage() {
        this.isCroppedImage = true;
    }

    uploadImage(): void {
        this.showLoader = true;

        fetch(this.croppedImage)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onload = () => {
                    if (!reader.result) {
                        // TODO: Añadir mensaje de excepcion si no llega archivo (Un pop up por ejemplo)
                        return false;
                    }

                    if (typeof reader.result === "string") {
                        const base64 = reader.result.split(',')[1];

                        this.uploadService.uploadFiles(base64, this.reference, "documentos").subscribe(
                            {
                                next: next => {
                                    this.messageAlertUploadFoto = next.message;
                                    this.showAlertUploadFoto = !this.showAlertUploadFoto;
                                    this.showLoader = false;

                                    this.ngOnInit();
                                }, error: error => {
                                    this.messageAlertUploadFoto = error.message;
                                    this.showAlertUploadFoto = !this.showAlertUploadFoto;

                                    this.showLoader = false;
                                }
                            }
                        );
                    }

                    return true;
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error("Error al convertir Object URL en Base64: " + error);
            });
    }

    closeAlertError() {
        this.showAlertUploadFoto = !this.showAlertUploadFoto;
    }
}
