<app-navbar [nameSection]="nameSection" [nameXofer]="nomXofer"></app-navbar>

<div *ngIf="loaderShow" class="loader-container">
    <div class="overlay">
        <div class="loader"></div>
    </div>
</div>

<div class="container-fluid mt-3 mb-3">
    <div class="mb-2">
        <select #selectElement class="form-control" (change)="changeToken();" [(ngModel)]="selectedTuition">
            <ng-container *ngIf="matriculas.length > 0">
                <ng-container *ngFor="let matricula of matriculas">
                    <option value="{{matricula.matricula}}">{{ matricula.matricula }}</option>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="matriculas.length === 0">
                <option value="{{selectedTuition}}">{{selectedTuition}}</option>
            </ng-container>
        </select>
    </div>

    <div class="mb-2" style="display: flex;">
        <input (change)="getAlbaranesFiltrados()" [(ngModel)]="inputFiltroAlbaran" class="form-control"
               placeholder="Filtrar por ID albarán"
               style="margin-right: 10px;" type="text">
        <input (change)="getAlbaranesFiltrados()" (click)="vaciarInputDate()" [(ngModel)]="inputFiltroAlbaranDate"
               class="form-control"
               style="margin-right: 2px;" type="date">
    </div>
    <button (click)="clearFilters()" *ngIf="!boolHiddeBtnClearFilters" class="form-control mb-2"
            id="btnClearFilters" style="background-color: #3c2170; color: white">
        Limpiar filtros de referencia y/o fecha del albarán
    </button>
    <button [routerLink]="'/lector-qr'" class="form-control mb-2" id="btnSearchAlbaranQR"
            style="background-color: #3c2170; color: white">
        Buscar albarán por código QR
    </button>

    <button (click)="openModalAdvanceAlbaran()" class="form-control" style="background-color: #3c2170; color: white">
        Avanzar albaran
    </button>

    <div class="mt-3" id="spanTareasPendientesGenerales">
        <h2>
            Total de tareas pendientes: {{ tareasHechasTotales }}/{{ tareasPendientesTotales }}
        </h2>
    </div>
</div>

<ng-container *ngFor="let albaran of albaranes">
    <ul class="list-group mb-3">
        <li [routerLink]="['/albaran', albaran.reference, albaran.idTransport, albaran.id]"
            class="list-group-item {{albaran.estat}}"
            style="cursor: pointer;">
            <div class="ms-2 me-auto">
                <div class="fw-bold">
                    Albarán: {{ albaran.reference }}
                    <span class="float-end">{{ albaran.numeroTareasHechas }}/{{ albaran.numeroTareas }}</span>
                </div>
                <b>Cliente: </b> {{ albaran.nameClient }}<br>
                <b>Referencia cliente: </b> {{ albaran.refClient }}<br>
                <b>Modo transporte: </b> {{ albaran.modeTransport }}<br>
                <b>O: </b> {{ albaran.origen }}<br>
                <b>D: </b> {{ albaran.desti }}<br>

                {{ albaran.tipusTransport }}
                - <span style="color: red">{{ albaran.esAdr === 1 ? 'ADR|' : '' }}</span>
                <span style="color: red">{{ albaran.esPortaElevadora === 1 ? 'PE|' : '' }}</span>
                <span style="color: red">{{ albaran.esExpress === 1 ? 'EX|' : '' }}</span>
                <span style="color: red">{{ albaran.esRemuntable === 1 ? 'R|' : '' }}</span>
                <span style="color: red">{{ albaran.pnstrm === 1 ? 'PNS' : '' }}</span>
                - {{ albaran.pes }}k|{{ albaran.numeroBultos }}b|{{ albaran.volum | number:'1.2-2' }}m³<br>
            </div>
        </li>
    </ul>
</ng-container>

<div *ngIf="showAlertErrorGetAlbaranes" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageErrorGetAlbaranes }}</p>
            <button (click)="closeAlertError()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<div *ngIf="showQuestionDestiny" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca el primer sitio donde vas a ir.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let destinys of listFirstDestinyXofer">
                <div class="content col-12">
                    <input class="form-check-input col-2" type="checkbox" id="{{destinys.idLloc}}"
                           [checked]="selectedCheckboxId === destinys.idLloc.toString()"
                           (click)="saveDestinationPlaceId(destinys.idLloc)">
                    <label class="form-check-label col-8" for="{{destinys.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ destinys.nom }} - {{ destinys.cliente }} - {{ destinys.bultos }} - {{ destinys.kg }}
                        - {{ destinys.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button class="form-control mb-2" (click)="saveFirstDestiny()">
                Marcar dirección
            </button>
            <button class="form-control" (click)="closeSaveFirstDestiny()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showAlertSaveTracking" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertSaveTracking }}</p>
            <button (click)="closeAlertSaveFirstDestiny()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<div *ngIf="showAdvanceAlbaran" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>¿Qué albarán quieres avanzar? Introduce el número de forma manual o escanea el código QR.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content mb-2">
            <div *ngIf="showCameraAdvanceAlbarans" class="content col-12">
                <input class="form-control" [(ngModel)]="albaranId">
            </div>

            <div class="content col-12">
                <div *ngIf="showCardAdvanceAlbaran" class="card">
                    <div class="card-body">
                        <div class="fw-bold">
                            Albarán: {{ advancedAlbaran.reference }}
                        </div>
                        <b>Cliente: </b> {{ advancedAlbaran.nameClient }}<br>
                        <b>Referencia cliente: </b> {{ advancedAlbaran.refClient }}<br>
                        <b>Modo transporte: </b> {{ advancedAlbaran.modeTransport }}<br>
                        <b>O: </b> {{ advancedAlbaran.origen }}<br>
                        <b>D: </b> {{ advancedAlbaran.desti }}<br>

                        {{ advancedAlbaran.tipusTransport }}
                        - <span style="color: red">{{ advancedAlbaran.esAdr === 1 ? 'ADR|' : '' }}</span>
                        <span style="color: red">{{ advancedAlbaran.esPortaElevadora === 1 ? 'PE|' : '' }}</span>
                        <span style="color: red">{{ advancedAlbaran.esExpress === 1 ? 'EX|' : '' }}</span>
                        <span style="color: red">{{ advancedAlbaran.esRemuntable === 1 ? 'R|' : '' }}</span>
                        <span style="color: red">{{ advancedAlbaran.pnstrm === 1 ? 'PNS' : '' }}</span>
                        - {{ advancedAlbaran.pes }}k|{{ advancedAlbaran.numeroBultos }}b|{{ advancedAlbaran.volum | number:'1.2-2' }}m³<br>
                    </div>
                </div>
                <div *ngIf="showCameraAdvanceAlbarans">
                    <div>
                        <zxing-scanner #scanner (camerasFound)="onCamerasFound($event)"
                                       [formats]="formatsEnabled"
                                       (camerasNotFound)="browserError('No existen camaras disponibles')"
                                       (deviceChange)="onDeviceChange($event)"
                                       (permissionResponse)="onHasPermission($event)"
                                       (scanSuccess)="checkBrowser($event)"
                                       (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
                    </div>

                    <div [ngClass]="{show: showCheck}" class="success-checkmark">
                        <div class="check-icon">
                            <span class="icon-line line-tip"></span>
                            <span class="icon-line line-long"></span>
                            <div class="icon-circle"></div>
                            <div class="icon-fix"></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showMessageAdvancedAlbaranNotFound">
                    <p>
                        El albarán que has introducido no se ha podido encontrar o no existe.
                    </p>
                </div>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button *ngIf="showCameraAdvanceAlbarans" class="form-control mb-2" (click)="advanceAlbaran()">
                Encontrar albarán
            </button>
            <button *ngIf="showCardAdvanceAlbaran" class="form-control mb-2" (click)="saveAdvancedAlbaran(advancedAlbaran.reference)">
                Avanzar albarán
            </button>
            <button *ngIf="showMessageAdvancedAlbaranNotFound || showCardAdvanceAlbaran" class="form-control mb-2" (click)="retryFoundAdvancedAlbaran()">
                Volver a buscar el albarán
            </button>
            <button class="form-control" (click)="closeModalAdvanceAlbaran()">
                Cerrar
            </button>
        </div>
    </div>
</div>

<app-footer></app-footer>
