<div class="card mb-3" id="cardIncidenceProgress">
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <span><b>Incidencia</b></span>
            </div>
        </div>
    </div>
    <div class="card-body row">
        <div class="col-12 mb-2">
            <label for="inputTitolIncidenceProgress">Título <span style="color: red">*</span></label>
            <input #titol="ngModel" [(ngModel)]="incidencia.titol" class="form-control"
                   id="inputTitolIncidenceProgress" name="titol" placeholder="Introducir título..." required
                   type="text">
        </div>
        <div class="col-12 mb-2">
            <label for="inputTitolIncidenceProgress">Comentarios</label>
            <br>
            <textarea #comentari="ngModel" [(ngModel)]="incidencia.comentari"
                      class="form-control" name="comentari" placeholder="Introducir comentarios..."
                      style="width: 100%;"></textarea>
        </div>
        <div class="col-12">
            <span>Los campos marcados con <span style="color: red">*</span> son obligatorios</span>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 mb-2">
                    <input (change)="previewImage($event)" id="fotoIncidenciaInput"
                           name="{{newIncidencia.idCheck}}" type="file">
                    <label class="form-control btn w-100" for="fotoIncidenciaInput" id="fotoIncidenciaLabel">
                        <span>Foto incidencia</span>
                    </label>
                </div>
                <div class="col-12">
                    <button (click)="sendIncidencia(newIncidencia.idCheck, newIncidencia.idLloc)"
                            [hidden]="!boolSendIncidencia"
                            class="form-control" name="{{newIncidencia.idCheck}}"
                            style="background: #f2e938; border-color: #f2e938; color: black;">
                        <b>CREAR INCIDENCIA</b>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ALERTAS DE OK Y KO EN LA SUBIDA DE LA IMAGEN DE LA INCIDENCIA -->
<div *ngIf="showAlertSuccessUploadPhotoIncidencia" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSuccessIncidencia }}</p>
            <button (click)="closeAlertSuccessIncidencia()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>
<div *ngIf="showAlertErrorUploadPhotoIncidencia" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageErrorIncidencia }}</p>
            <button (click)="closeAlertErrorIncidencia()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE OK Y KO EN LA SUBIDA DE LA INCIDENCIA -->
<div *ngIf="showAlertSendIncidencia" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSendIncidencia }}</p>
            <button (click)="closeAlertSendIncidencia()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<div class="container-fluid">
    <button #buttonModal [attr.data-bs-target]="'#cesta'" [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'staticBackdropLabel'" [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="cesta" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="staticBackdropLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img alt="" height="350" src="data:image/png;base64,{{base64image}}" width="255">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="uploadFotoIncidencia()" class="form-control mb-2" data-bs-dismiss="modal"
                            type="button">
                        Guardar
                    </button>
                    <button class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
