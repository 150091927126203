import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AlbaranListComponent } from './components/albaran/albaran-list/albaran-list.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { AlbaranContentComponent } from './components/albaran/albaran-content/albaran-content.component';
import { AlbaranUploadPhotoComponent } from './components/albaran/albaran-upload-photo/albaran-upload-photo.component';
import { AlbaranProgressComponent } from './components/albaran/albaran-progress/albaran-progress.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { QrReaderPage } from "./components/albaran/qr-reader/qr-reader.page";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { IonicModule } from "@ionic/angular";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { AlbaranProgressIncidenciaListComponent } from './components/albaran/albaran-progress-incidencia-list/albaran-progress-incidencia-list.component';
import { AlbaranProgressNewIncidenciaComponent } from './components/albaran/albaran-progress-new-incidencia/albaran-progress-new-incidencia.component';
import { SplashComponent } from './components/splash/splash.component';
import { ImageCropperModule } from "ngx-image-cropper";
import { AlbaranImagesComponent } from './components/albaran/albaran-images/albaran-images.component';
import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SplashComponent,
        AlbaranListComponent,
        NotFoundComponent,
        AlbaranContentComponent,
        AlbaranUploadPhotoComponent,
        AlbaranProgressComponent,
        NavbarComponent,
        QrReaderPage,
        AlbaranProgressIncidenciaListComponent,
        AlbaranProgressNewIncidenciaComponent,
        AlbaranImagesComponent,
        FooterComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        ZXingScannerModule,
        IonicModule.forRoot(),
        AutocompleteLibModule,
        ImageCropperModule
    ], providers: [
        AlbaranListComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
