<div class="card mb-3 mt-2" id="cardIncidenceProgress">
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <span><b>Incidencia</b></span>
            </div>
            <div class="col-6" style="padding: 4px;">
                <button (click)="minimizeIncidence(incidencia)" class="form-control float-end"
                        id="buttonMinimizarIncidencia" style="width: 10px; background-color: #3c2170">
                </button>
            </div>
        </div>
    </div>
    <div [hidden]="incidencia.incidenciaMinimizada" class="card-body row">
        <div class="col-12 mb-2">
            <label for="inputTitolIncidenceProgress">Título <span style="color: red">*</span></label>
            <input #titol="ngModel" [(ngModel)]="incidencia.titol" class="form-control"
                   disabled id="inputTitolIncidenceProgress" name="titol" placeholder="Introducir título..."
                   required type="text">
        </div>
        <div class="col-12 mb-2">
            <label for="inputComentariIncidenceProgress">Comentarios</label>
            <br>
            <textarea #comentari="ngModel" [(ngModel)]="incidencia.comentari" class="form-control"
                      disabled id="inputComentariIncidenceProgress" name="comentari"
                      placeholder="Introducir comentarios..." style="width: 100%;">
            </textarea>
        </div>
    </div>
</div>
