<div *ngIf="showSplash" [ngStyle]="{ left: windowWidth }" class="app-splash-screen">
    <div class="app-splash-inner">
        <div class="app-label">TRM Xofers</div>
        <div class="app-loader">
            <div class="loadingio-spinner-ripple-h7v44myj0m">
                <div class="ldio-i3eo5bovqli">
                    <div></div>
                    <div></div>
                </div>
            </div>

        </div>
    </div>
</div>

