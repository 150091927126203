<app-navbar [nameSection]="name"></app-navbar>

<!-- LOADER -->
<div *ngIf="showLoader" class="loader-container">
    <div class="overlayLoader">
        <div class="loader"></div>
    </div>
</div>

<!-- CHECKLIST, BOTONES IMAGENES Y INCIDENCIAS -->
<div *ngFor="let check of checkLists" class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <input (click)="clickCheck($event, check.nom , check.taskCode)" *ngIf="check.checked === 0 || check.codi; else checked"
                   [disabled]="hasDisableTaskList(check.statusRoute) || isDateExpired(check.transportDate)"
                   class="form-check-input col-2"
                   id="{{check.idCheck}}" name="{{check.idLloc}}"
                   type="checkbox">
            <label *ngIf="check.checked === 0 || check.codi" class="form-check-label col-10"
                   for="{{check.id}}" style="margin-top: -5px; margin-left: 5px;">
                {{ check.nom }} - {{ check.nomDesc }}
                <span [hidden]="!hasDisableTaskList(check.statusRoute)">
                    ({{ check.statusRoute }})
                </span>
            </label>
        </div>

        <ng-container
            *ngIf="modeTransport === TransportMode.aerial && tipusTransport === 'Exportació' && check.taskCode === CheckListTaskDriver.arrivalAtUnloadingPoint && check.deliveryNoteDestination">
            <div class="col-12 mt-3">
                <button (click)="openModalCheckBultosDestinyAWB(check.idCheck , check.originDeliveryNotePlace)"
                        [disabled]="hasDisableTaskList(check.statusRoute) || isDateExpired(check.transportDate)"
                        [attr.aria-disabled]="hasDisableTaskList(check.statusRoute) || isDateExpired(check.transportDate)"
                        [ngClass]="{'clase-css-true': check.isCheckedBultosDestinyAWB === 1, 'clase-css-false': check.isCheckedBultosDestinyAWB === 0}"
                        class="form-control"
                        id="buttonCheckBultosDestinyAWB"
                        style="height: 43px">
                    Verificar bulto/s y destino AWB
                </button>
            </div>
            <div class="col-12 mt-2">
                <button (click)="openModalCheckAWB(check.bultos , check.idCheck)"
                        [disabled]="hasDisableTaskList(check.statusRoute) || isDateExpired(check.transportDate)"
                        [attr.aria-disabled]="hasDisableTaskList(check.statusRoute) || isDateExpired(check.transportDate)"
                        [ngClass]="{'clase-css-true': check.isCheckedAWB === 1, 'clase-css-false': check.isCheckedAWB === 0}"
                        class="form-control" id="buttonBarcode"
                        style="height: 43px">
                    Verificar etiqueta/s y número AWB
                </button>
            </div>
        </ng-container>

        <ng-template #checked>
            <div class="col-12">
                <input (click)="clickCheck($event, check.nom , check.taskCode)" checked class="form-check-input col-2"
                       disabled id="{{check.idCheck}}" type="checkbox">
                <label class="form-check-label col-10" for="{{check.id}}" style="margin-top: -5px; margin-left: 5px;">
                    {{ check.nom }} - {{ check.nomDesc }}
                </label>
            </div>

            <div class="col-12 mt-3" style="display: grid; justify-items: center;">
                <input (change)="previewUploadFotoLlegadasSalidas($event, check.idAlbara, check.idCheck, false, '')"
                       id="albaranPhotoCheckInput" name="file"
                       type="file">
                <label #clickIncidencia class="form-control btn w-100" for="albaranPhotoCheckInput"
                       id="albaranPhotoCheckLabel">
                    <span>Foto conforme llegada o salida.</span>
                </label>

                <div *ngIf="check.hasImage === 1" class="card mb-3 mt-2 w-100" id="cardPhotoDone">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <span><b>Foto hecha</b></span>
                            </div>
                            <div class="col-6">
                                <button [routerLink]="['/show-images', check.idAlbara, idTransport]"
                                        class="form-control float-end"
                                        id="buttonMinimizarIncidencia" style="background-color: #3c2170; padding: 0;">
                                    Ver fotografías
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="row mt-2">
        <div class="col-2">
            <input (click)="clickIncidecia(check.id)"
                   *ngIf="!check.incidenciaMarcada || check.codi; else checkedIncidencia"
                   class="form-check-input mt-2" id="inputCheckboxIncidence"
                   name="{{check.idCheck}}" type="checkbox">
        </div>

        <ng-template #checkedIncidencia>
            <div *ngIf="!check.incidenceDisabled" class="col-2">
                <input (click)="clickIncidecia(check.id)"
                       checked class="form-check-input mt-2 mb-2" id="inputCheckboxIncidence2"
                       name="{{check.idCheck}}" type="checkbox">
            </div>

            <div *ngIf="check.incidenceDisabled" class="col-2">
                <input (click)="clickIncidecia(check.id)"
                       checked class="form-check-input mt-2 mb-2" disabled
                       id="inputCheckboxIncidence3" name="{{check.idCheck}}" type="checkbox">
            </div>
        </ng-template>

        <label class="form-check-label col-10"
               for="{{check.id}}"
               id="labelCheckboxIncidencias"
               [ngClass]="{'label-disabled': hasDisableTaskList(check.statusRoute)}"
               style="margin-top: 10px;">Incidencia</label>

        <div class="col-12 mt-3" style="display: grid; justify-items: center;">
            <button (click)="generateNewIncidencia(check.id)" *ngIf="check.incidencia.length > 0"
                    class="form-control" style="height: 42px;">
                Añadir nueva incidencia
            </button>
        </div>
    </div>

    <ng-container *ngFor="let incidencia of check.incidencia">
        <app-albaran-progress-incidencia-list [incidencia]="incidencia"></app-albaran-progress-incidencia-list>
    </ng-container>

    <ng-container *ngIf="check.newIncidence">
        <app-albaran-progress-new-incidencia [ididAlbara]="idAlbara" [idTransport]="idTransport"
                                             [newIncidencia]="check"></app-albaran-progress-new-incidencia>
    </ng-container>
    <hr>
</div>

<!-- MODALS CONFIRMACIÓN FOTOGRAFÍA -->

<!-- MODAL CROPPER -->
<div class="container-fluid">
    <button #buttonModal [attr.data-bs-target]="'#cesta'" [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'staticBackdropLabel'" [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="cesta" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="staticBackdropLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <image-cropper
                        (imageCropped)="imageCropped($event)"
                        [hidden]="isCroppedImage || boolUploadLlegadaSalida"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="false"
                        format="jpeg"
                    ></image-cropper>

                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" alt="" height="350" width="255">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" height="350"
                             src="data:image/png;base64,{{base64image}}" width="255">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="cropImage()" [hidden]="isCroppedImage || boolUploadLlegadaSalida"
                            class="form-control mb-1" type="button">
                        Recortar imagen y CONFIRMAR
                    </button>

                    <button (click)="uploadFotoAlbaran()" [hidden]="!isCroppedImage"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="uploadFotoLlegadasSalidas(true)" [hidden]="!boolUploadLlegadaSalida"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>

                    <button (click)="closeModal()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOTO LLEGADAS O SALIDAS -->
<div class="container-fluid">
    <button #individualPhotoLlegadasSalidasModal [attr.data-bs-target]="'#individualPhotoLlegadasSalidas'"
            [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'individualPhotoLlegadasSalidasLabel'"
         [attr.data-bs-backdrop]="'static'"
         [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="individualPhotoLlegadasSalidas" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="individualPhotoLlegadasSalidasLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" alt="" height="350" width="255">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" height="350"
                             src="data:image/png;base64,{{base64image}}" width="255">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="uploadFotoLlegadasSalidas(false)"
                            class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="closeModal()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL FOTO SRCB -->
<div class="container-fluid">
    <button #modalMandatoryPhotoSRCB [attr.data-bs-target]="'#mandatoryPhotoSRCB'"
            [attr.data-bs-toggle]="'modal'" [hidden]="buttonHidden"
            class="btn btn-dark"></button>

    <div [attr.aria-hidden]="'true'" [attr.aria-labelledby]="'mandatoryPhotoSRCBLabel'"
         [attr.data-bs-backdrop]="'static'" [attr.data-bs-keyboard]="'false'"
         class="modal fade" id="mandatoryPhotoSRCB" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="display: flex; justify-content: center; align-items: center;">
                    <h5 class="modal-title" id="mandatoryPhotoSRCBLabel">Confirmación imagen</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                        <img [hidden]="!isCroppedImage" [src]="croppedImage" alt="" height="350" width="255">
                        <img *ngIf="boolUploadLlegadaSalida" alt="" height="350"
                             src="data:image/png;base64,{{base64image}}" width="255">
                    </div>
                </div>
                <div class="modal-footer" style="display: flex; justify-content: center; align-items: center;">
                    <button (click)="uploadPhotoSRCB()" class="form-control mb-1" data-bs-dismiss="modal" type="button">
                        Guardar
                    </button>
                    <button (click)="closeModalSRCB()" class="form-control" data-bs-dismiss="modal" type="button">
                        Borrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODALS PREGUNTAS FLUJO CHECKLIST -->

<!-- MODALS LLPC -->
<div *ngIf="showListLlegadasLLPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLlegadas">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="marcarMasAlbaranesLlegada()" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesLlegadas()" class="form-control">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPC -->
<div *ngIf="showListMoreAlbaransSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca todos los albaranes para salir de este sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesSPC">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="marcarMasAlbaranesSPC()" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesSPC()" class="form-control mb-2">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListExtraPhotosSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube las fotografías de salida obligatorias de cada albarán</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="photoBillingExtra">
            <ng-container *ngFor="let albaran of albaranesMarcadosLlegadas">
                <div [id]="getId(albaran.idAlbara)" class="content col-12">
                    <input
                        (change)="previewUploadFotoLlegadasSalidas($event, albaran.idAlbara, albaran.idCheck, true, 'SPC')"
                        id="{{albaran.idAlbara}}" name="file" type="file">
                    <label class="form-control btn w-100" for="{{albaran.idAlbara}}"
                           id="albaranPhotoSalidasExtraLabel-{{albaran.idAlbara}}">
                        <span>{{ albaran.idAlbara }}</span>
                        <br>
                        <span>{{ albaran.bultos }}b|{{ albaran.kg }}kg|{{ albaran.volumen | number:'1.2-2' }}m³</span>
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalPhotosBillingsExtra()" [disabled]="!billingsPhotosDone" class="form-control">
                {{ isMultitasking ? "Siguiente" : "Terminar" }}
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListNextPointsSPC" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Hacia qué sitio te diriges ahora</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let llocs of llocsAlbaranSPC">
                <div class="content col-12">
                    <input (click)="saveDestinationPlaceId(llocs.idLloc)"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()" class="form-check-input col-2"
                           id="{{llocs.idLloc}}"
                           type="checkbox">
                    <label class="form-check-label col-8 labels-items-place" for="{{llocs.idLloc}}">
                        {{ llocs.nom }} | {{ llocs.cliente }} | {{ llocs.bultos }}b | {{ llocs.kg }}k | {{ llocs.reference }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="sendMoreAlbaransSPC()" class="form-control">
                Enviar
            </button>
        </div>
    </div>
</div>

<!-- MODALS LLPD -->
<div *ngIf="showListLLPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLLPD">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <hr>
        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="marcarMasAlbaranesLLPD()" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesLLPD()" class="form-control">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPD -->
<div *ngIf="showListMoreAlbaransSPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca todos los albaranes para salir de este sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesSPD">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="sendMoreAlbaransSPD()" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesSPD()" class="form-control mb-2">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>
<div *ngIf="showListPhotosPodSPD" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube las fotografías de salida obligatorias de cada albarán (POD)</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="photoBillingExtraSPD">
            <ng-container *ngFor="let albaran of albaranesMarcadosLlegadas">
                <div [id]="getIdPOD(albaran.idAlbara)" class="content col-12">
                    <input (change)="filePhotoBillingsExtra($event, albaran.idAlbara)" id="{{albaran.idAlbara}}"
                           name="file"
                           type="file">
                    <label class="form-control btn w-100" for="{{albaran.idAlbara}}"
                           id="albaranPhotoSalidasExtraLabel-{{albaran.idAlbara}}">
                        <span>{{ albaran.idAlbara }}</span>
                        <br>
                        <span>{{ albaran.bultos }}b|{{ albaran.kg }}kg|{{ albaran.volumen | number:'1.2-2' }}m³</span>
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalPhotosBillingsExtraSPD()" [disabled]="!billingsPhotosPODDone"
                    class="form-control">
                Terminar
            </button>
        </div>
    </div>
</div>

<!-- MODALS LLPP -->
<div *ngIf="showListLLPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Marca los albaranes extra que vas a hacer en este mismo sitio.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesLLPP">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="marcarMasAlbaranesLLPP()" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesLLPP()" class="form-control">
                No marcar ninguno más
            </button>
        </div>
    </div>
</div>

<!-- MODALS SPP -->
<div *ngIf="showListSPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3 *ngIf="showListMoreAlbarans">Marca todos los albaranes para salir de este sitio.</h3>
            <h3 *ngIf="showListNextPointsSPP && !showListMoreAlbarans">Hacia que sitio te diriges ahora?</h3>
        </div>

        <div *ngIf="showListMoreAlbarans" class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let albaran of albaranesSPP">
                <div class="content col-12">
                    <input (click)="saveDeliveryNoteWaypoints(albaran, $event)" class="form-check-input col-2"
                           id="{{albaran.id}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{albaran.id}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ albaran.id }} - {{ albaran.nomClient }} - {{ albaran.refClient }} - {{ albaran.bultos }}b
                        - {{ albaran.pes }}kg - {{ albaran.volum }}m³
                    </label>
                </div>
            </ng-container>
        </div>
        <div *ngIf="showListNextPointsSPP && !showListMoreAlbarans"
             class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let llocs of llocsAlbaranSPP">
                <div class="content col-12">
                    <input (click)="saveDestinationPlaceId(llocs.idLloc)"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()" class="form-check-input col-2"
                           id="{{llocs.idLloc}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{llocs.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ llocs.nom }} - {{ llocs.cliente }} - {{ llocs.bultos }} - {{ llocs.kg }}
                        - {{ llocs.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="marcarMasAlbaranesSPP()" *ngIf="showListMoreAlbarans" class="form-control mb-2">
                Marcar los siguientes albaranes
            </button>
            <button (click)="closeMarcarAlbaranesSPP()" *ngIf="showListMoreAlbarans" class="form-control mb-2">
                No marcar ninguno más
            </button>
            <button (click)="sendMoreAlbaransSPP()" *ngIf="showListNextPointsSPP && !showListMoreAlbarans"
                    class="form-control">
                Enviar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showListNextPointsSPP && !showListSPP" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Hacia que sitio te diriges ahora?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2">
            <ng-container *ngFor="let llocs of llocsAlbaranSPP">
                <div class="content col-12" style="max-height: 200px; overflow-y: auto;">
                    <input (click)="saveDestinationPlaceId(llocs.idLloc)"
                           [checked]="selectedCheckboxId === llocs.idLloc.toString()" class="form-check-input col-2"
                           id="{{llocs.idLloc}}"
                           type="checkbox">
                    <label class="form-check-label col-8" for="{{llocs.idLloc}}"
                           style="margin-left: 10px; font-size: 15px !important; margin-top: 1px !important;">
                        {{ llocs.nom }} - {{ llocs.cliente }} - {{ llocs.bultos }} - {{ llocs.kg }}
                        - {{ llocs.numAlbaran }}
                    </label>
                </div>
            </ng-container>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="sendMoreAlbaransSPP()" class="form-control">
                Enviar
            </button>
        </div>
    </div>
</div>

<!-- MODALS SRCB -->
<div *ngIf="showMandatoryPhoto" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Sube la fotografía obligatoria de la salida</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content row mb-2" id="mandatoryPhoto">
            <div [hidden]="mandatoryPhotoDone" class="content col-12">
                <input (change)="previewUploadMandatoryPhotoSRCB($event)"
                       id="mandatoryPhotoInput" name="file" type="file">
                <label class="form-control btn w-100" for="mandatoryPhotoInput" id="mandatoryPhotoLabel">
                    <span>Fotografía obligatoria</span>
                </label>
            </div>
            <div [hidden]="!mandatoryPhotoDone" class="content col-12">
                <span>Fotografía hecha correctamente.</span>
            </div>
        </div>

        <hr>

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalMandatoryPhotoSRCB()" [disabled]="!mandatoryPhotoDone" class="form-control">
                Terminar
            </button>
        </div>
    </div>
</div>

<!-- MODALS COMPROBACIÓN BULTOS Y DESTINO AWB -->
<div *ngIf="showCheckBultosAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>¿Las marcas del bulto corresponden con {{ originNamesLinkedAlbarans }}?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkBultosAWB">
            <div [hidden]="checkedAppropiate" class="content col-12">
                <button (click)="isAppropriate()" class="form-control">Si</button>
                <button (click)="notIsAppropriate()" class="form-control mt-2">No</button>
            </div>

            <div [hidden]="!isNotAppropiate" class="content col-12">
                <button (click)="goBackBultos()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <p>
                    Por favor llama a la oficina del aeropuerto para comprobar la mercancía.
                </p>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalCheckBultosAWB()" class="form-control">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckDestinyAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>¿Coincide el destino de la etiqueta del AWB con el destino indicado en los bultos?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkDestinyAWB">
            <div [hidden]="checkedAppropiateDestiny" class="content col-12">
                <button (click)="goBackToBultos()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>

                <button (click)="isAppropriateDestiny()" class="form-control">Si</button>
                <button (click)="notIsAppropriateDestiny()" class="form-control mt-2">No</button>
                <button (click)="checkDestinyIATA()" class="form-control mt-2">Consultar siglas destino, código IATA
                </button>
            </div>

            <div [hidden]="!isNotAppropiateDestiny" class="content col-12">
                <button (click)="goBackDestiny()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <p>
                    Por favor si el destino de la etiqueta no coincide pongase en contacto con el personal de la
                    oficina.
                </p>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalCheckDestinyAWB()" class="form-control">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckDestinyIATA" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3 [hidden]="checkedAppropiateDestinyIATA">¿Coincide el destino de la etiqueta del AWB con el destino
                indicado en los bultos?</h3>
            <h3 [hidden]="!checkedAppropiateDestinyIATA"><b>ATENCIÓN</b></h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkDestinyIATA">
            <div [hidden]="checkedAppropiateDestinyIATA" class="content col-12">
                <button (click)="goBackCheckIATA()" class="form-control mb-4" style="width: 40px !important;">
                    <i class="fa fa-arrow-left"></i>
                </button>

                <label for="inputCheckDestinyIATA">Consultar siglas destino, código IATA</label>
                <input [(ngModel)]="codeIATAtoCheck" class="form-control" id="inputCheckDestinyIATA" type="text">
            </div>

            <div [hidden]="!checkedAppropiateDestinyIATA" class="content col-12">
                <span>
                    Este es el el aeropuerto/ciudad: {{ nomAeroport }}/{{ municipiAeroport }}
                    y el país: {{ paisAeroport }}.
                    <b>¿Coincide el destino de la etiqueta del AWB con el destino indicado en los bultos?</b>
                </span>
                <button (click)="isAppropriateDestiny()" class="form-control mt-2">Si</button>
                <button (click)="notIsAppropriateDestinyIATA()" class="form-control mt-2">No</button>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="funcCheckDestinyIATA()" [hidden]="checkedAppropiateDestinyIATA" class="form-control mb-2">
                Comprobar IATA
            </button>
            <button (click)="closeModalCheckDestinyIATA()" class="form-control">
                Cerrar
            </button>
        </div>
    </div>
</div>

<!-- MODALS COMPROBACIÓN AWB -->
<div *ngIf="showCheckAWB" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Asegúrate que la etiqueta está pegada en el bulto. ¿Qué verificación vas a seguir?</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWB">
            <div class="content col-12">
                <button (click)="partialCheck()" class="form-control">Simple (Verificar la etiqueta de x1 bulto)
                </button>
                <button (click)="totalCheck()" class="form-control mt-2">
                    Total (Verificar&nbsp;
                    <span *ngIf="bultos === '1'">la etiqueta del bulto</span>
                    <span *ngIf="bultos !== '1'">las etiquetas de los {{ bultos }} bultos</span>&nbsp;del albarán)
                    <span *ngIf="isDeliveryNoteSplit && remainingPackages == 1">. Queda un bulto por revisar.</span>
                    <span *ngIf="isDeliveryNoteSplit && remainingPackages != 1">. Quedan {{ remainingPackages}} bultos por revisar.</span>
                </button>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalCheckAWB()" class="form-control">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBScanner" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Escanea los códigos de barras de la etiqueta para verificar el bulto.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBscanner">
            <div class="content col-12">
                <button (click)="scannBarcodeManually()" class="form-control mb-4"
                        style="background-color: #f2e938; color: black; border-color: #f2e938">
                    <b>Verificación manual número AWB</b>
                </button>

                <div class="qr-scanner">
                    <div class="box">
                        <div class="line"></div>
                        <div class="angle"></div>
                    </div>
                </div>

                <div>
                    <zxing-scanner #scanner (camerasFound)="onCamerasFound($event)"
                                   (camerasNotFound)="browserError('No existen cámaras disponibles')"
                                   (deviceChange)="onDeviceChange($event)"
                                   (permissionResponse)="onHasPermission($event)"
                                   (scanSuccess)="checkBrowser($event)"
                                   (torchCompatible)="onTorchCompatible($event)"
                                   [formats]="formatsEnabled"></zxing-scanner>
                </div>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="closeModalCheckAWBScanner()" class="form-control">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBManually" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Introduce manualmente el número de AWB para su verificación.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBManually">
            <div class="content col-12">
                <label for="inputCheckBarcodeManually">Introduce el número AWB</label>
                <input #inputCheckBarcodeManually class="form-control" id="inputCheckBarcodeManually" type="text">
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="sendBarcodeManually()" class="form-control">
                Enviar
            </button>
            <button (click)="closeModalCheckAWBManually()" class="form-control mt-2">
                Cerrar
            </button>
        </div>
    </div>
</div>

<div *ngIf="showCheckAWBDone" class="overlay">
    <div class="listAlbaranesLlegadasParaMarcar">
        <div class="listAlbaranesLlegadasParaMarcar-header">
            <h3>Escanea los códigos de barras del AWB para verificar el bulto.</h3>
        </div>

        <div class="listAlbaranesLlegadasParaMarcar-content" id="checkAWBDone">
            <div class="content col-12">
                <span id="{{idMessageCheckAWBDone}}">
                    {{ messageCheckAWBDone }}
                </span>
            </div>
        </div>

        <hr style="margin: 0 0 !important;">

        <div class="listAlbaranesLlegadasParaMarcar-footer">
            <button (click)="scannNextBarcode()" [hidden]="!isTotalSuccess || isPartial" class="form-control">
                Escanear siguiente código de barras
            </button>
            <button (click)="endBarcodeTask()" class="form-control mt-2" *ngIf="isDeliveryNoteSplit">
                <span *ngIf="isTotalSuccess && isDeliveryNoteSplit">Terminar proceso, resto de bultos los lleva otro vehículo.</span>
            </button>
            <button (click)="closeModalCheckAWBDone()" class="form-control mt-2" *ngIf="isDeliveryNoteSplit">
                <span *ngIf="isTotalSuccess && isDeliveryNoteSplit">Cerrar(Proceso no finalizado)</span>
            </button>
            <button (click)="closeModalCheckAWBDone()" class="form-control mt-2"  *ngIf="!isDeliveryNoteSplit">
                <span *ngIf="isTotalSuccess">Cerrar(Proceso no finalizado)</span>
                <span *ngIf="!isTotalSuccess">Terminar proceso</span>
            </button>
        </div>
    </div>
</div>

<div *ngIf="showAlertSuccessScannBarcode" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSuccessScannBarcode }}</p>
            <button (click)="closeAlertSuccessScannBarcode()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTAS -->

<!-- ALERTAS DE OK Y KO EN LA SUBIDA DE LA IMAGEN DEL ALBARÁN -->
<div *ngIf="showAlertSuccessUploadPhotoAlbaran" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageSuccessAlbaran }}</p>
            <button (click)="closeAlertSuccessAlbaran()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>
<div *ngIf="showAlertErrorUploadPhotoAlbaran" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageErrorAlbaran }}</p>
            <button (click)="closeAlertErrorAlbaran()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE INCIDENCIA POR MARCAR CHECK TARDÍO -->
<div *ngIf="showAlertCheckTardio" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageCheckTardio }}</p>
            <button (click)="closeAlertCheckTardio()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE KO O OK FOTOGRAFIA LLEGADA O SALIDA -->
<div *ngIf="showAlertUploadFotoLLegaSalida" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertUploadFotoLLegaSalida }}</p>
            <button (click)="closeAlertUploadFotoLLegaSalida()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA DE KO O OK FOTOGRAFIA LLEGADA O SALIDA SI NO MARCA SITIO AL QUE SE DIRIGE -->
<div *ngIf="showAlertPlaceToGo" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertPlaceToGo }}</p>
            <button (click)="closeAlertPlaceToGo()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA FALLO AL CREAR LOS CHECKS -->
<div *ngIf="showAlertCreateChecks" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertCreateChecks }}</p>
            <button (click)="closeAlertCreateChecks()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>

<!-- ALERTA FALLO AL CREAR LOS CHECKS -->
<div *ngIf="showAlertAlbaranCompletedSECP" class="overlay">
    <div class="alert">
        <div class="alert-content">
            <p>{{ messageAlertAlbaranCompletedSECP }}</p>
            <button (click)="closeAlertAlbaranCompletedSECP()" class="form-control">Cerrar</button>
        </div>
    </div>
</div>
